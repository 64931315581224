<template>
  <headBackBar :iCustomizdde="true" :icon="true" class="pageHead">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </template>
    <template #default>
      <span>
        {{ $fanyi("会员") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
  </headBackBar>
  <div class="Content">
    <div class="user-box">
      <div class="img-box">
        <van-image
          lazy-load
          class="userPicture"
          :src="$store.state.userInfo?.portrait_url"
        >
          <template v-slot:loading>
            <img
              :src="require('@/assets/homePage/defaultUserHead.png')"
              alt=""
            />
          </template>
        </van-image>
      </div>
      <div class="text">
        <h2>{{ $store.state.userInfo?.realname }}</h2>
        <p
          v-if="
            $store.state.userInfo?.level.currentLevel.level_name == '定額会員'
          "
        >
          {{ $fanyi("收费会员") }}：{{
            $store.state.userInfo.level.currentLevel.end_date_format
          }}
          {{ $store.state.userInfo.level.currentLevel.end_time_format }} (残り{{
            getDateDifference(
              $store.state.userInfo.level.currentLevel.end_date
            )
          }}日)
        </p>
        <p v-else>{{ $fanyi("当前还不是收费会员") }}</p>
      </div>
    </div>
    <!-- 开通价格 -->
    <div class="money">
      <h3>{{ $fanyi("请选择开通时长") }}</h3>
      <div class="price-box">
        <div
          v-for="item in list"
          :key="item.days"
          class="item"
          :class="{ Checkedcolor: item.days == day }"
          @click="day = item.days"
        >
          <h3>{{ item.days + $fanyi("天") }}</h3>
          <div class="price">
            {{ item.pay }} <span>{{ $fanyi("円") }}</span>
          </div>
          <div class="Preferential" v-if="item.agio">
            {{ item.amount ? item.amount : "" }} 円 <span></span>
          </div>
          <div v-if="item.agio" class="dartprice">
            {{ $fanyi("立省") + item.agio }}円
          </div>
        </div>
      </div>

      <!-- 立即申请 -->
      <div class="formofapplication" @click="ApplicantMember">
        {{ $fanyi("立即申请") }}
      </div>

      <!--  会员享受优惠-->
      <div class="criteria">
        <h3>{{ $fanyi("当您是收费会员时") }}</h3>
        <p>{{ $fanyi("1.手续费 0%") }}</p>
        <p>{{ $fanyi("2.商品调查 免费 ") }}</p>
        <p>{{ $fanyi("3.商品详情介绍图片翻译") }}</p>
        <p>{{ $fanyi("4.订单为您优先处理") }}</p>
      </div>
    </div>
    <!-- 开通规则协议 -->
    <div class="regulation">
      <h3>{{ $fanyi("关于规则事项") }}</h3>
      <p>
        1、{{
          $fanyi(
            "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%。"
          )
        }}
      </p>
      <p>
        2、{{
          $fanyi(
            "申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了。"
          )
        }}
      </p>
      <p>
        3、{{
          $fanyi("确定为收费会员后，关于未支付的订单，手续费将自动变为0%。")
        }}
      </p>
      <p>
        4、{{
          $fanyi(
            "期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)"
          )
        }}
      </p>
      <p>
        5、{{
          $fanyi(
            "收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理。"
          )
        }}
      </p>
      <p>
        6、{{
          $fanyi(
            "请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案。"
          )
        }}
      </p>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { getDateDifference } from "@/utlis/date.js";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();

// 30 60 90
const day = ref(30);
const list = ref([]);
// 30天28000 60 天 50000  90 天70000
const money = ref(28000);

// 获取vip列表
const getuservipList = async () => {
  const res = await proxy.$api.uservipList();
  if (res.code !== 0) {
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  list.value = res.data;
};
getuservipList();
// 立即申请
const ApplicantMember = () => {
  // 判断余额 要大于支付金额  30天  balance
  if (day.value == 30) {
    money.value = 28000;
  } else if (day.value == 60) {
    money.value = 50000;
  } else if (day.value == 90) {
    money.value = 70000;
  }
  if (proxy.$store.state.userInfo.balance_availability < money.value) {
    Toast(proxy.$fanyi("余额不足，请先充值"));
    return;
  }
  proxy.$fun.routerToPage(
    "/balancePayTollVip?money=" + money.value + "&days=" + day.value
  );
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

// .headBox {
//   span {
//     font-size: 32px;
//     font-family: PingFang SC !important;
//     font-weight: 600;
//     color: #000000;
//   }
// }
.pageHead,
:deep().pageHead {
  background-color: #ffedea;
  * {
    background-color: #ffedea;
  }
}
.Content {
  padding-top: 1px;
  width: 750px;
  min-height: calc(100vh);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 150px;
  background: linear-gradient(
    180deg,
    #ffedea 0%,
    #f6f6f6 23%,
    #f6f6f6 56.99999999999999%
  );

  .user-box {
    margin: 20px 0 60px;
    width: 100%;
    height: 120px;
    display: flex;

    .img-box {
      display: flex;
      width: 120px;
      height: 120px;
      background: #ffdbd8;
      border-radius: 50%;

      :deep().van-image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ffdbd8;
        .van-image__loading {
          background-color: #ffdbd8;
        }
      }

      :deep().van-image__img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }
    }

    .text {
      padding-top: 25px;
      margin-left: 30px;

      h2 {
        font-size: 32px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 22px;
      }

      p {
        font-size: 20px;

        font-weight: 400;
        color: #999999;
      }
    }
  }

  .money {
    width: 690px;
    // height: 689px;
    background: #ffffff;
    border-radius: 6px;
    min-height: 613px;
    background: #ffffff;
    border-radius: 6px;
    padding: 48px 30px 30px;

    margin-bottom: 30px;

    h3 {
      font-size: 28px;

      font-weight: 600;
      color: #000000;
      margin-bottom: 35px;
    }

    .price-box {
      display: flex;
      justify-content: space-between;
      min-height: 208px;

      .item {
        width: 192px;
        height: 208px;
        padding-top: 30px;
        padding-left: 25px;
        padding-right: 25px;
        background: rgba(255, 255, 255, 0.2);
        border: 2px solid #f2f2f2;
        border-radius: 10px;

        h3 {
          width: 100%;
          text-align: center;
          margin-bottom: 15px;
          font-size: 30px;

          font-weight: 600;
          color: #333333;
        }

        .price {
          display: flex;
          width: 100%;
          justify-content: center;
          font-size: 40px;

          font-weight: 600;
          color: #d8a64d;
          margin-bottom: 10px;

          span {
            padding-left: 3px;
            padding-top: 15px;
            font-size: 20px;
          }
        }

        .Preferential {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          font-size: 20px;

          font-weight: 400;
          color: #999999;
          margin-bottom: 13px;

          span {
            position: absolute;
            top: 50%;
            width: 100px;
            height: 1px;
            background: #999999;
          }
        }

        .dartprice {
          margin: auto;
          width: 130px;
          height: 32px;
          background: #e7c281;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;

          font-weight: 400;
          color: #ffffff;
        }
      }

      .Checkedcolor {
        width: 192px;
        height: 208px;
        border: 2px solid #d8a64d;
        background: linear-gradient(270deg, #fff6e3 0%, #fffaef 100%);
        border-radius: 10px;
      }
    }

    .formofapplication {
      margin-top: 60px;
      width: 630px;
      height: 60px;
      background: linear-gradient(270deg, #ffd373 0%, #ffe5af 100%);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;

      font-weight: 400;
      color: #0f0218;
      margin-bottom: 48px;
    }

    .criteria {
      h3 {
        margin-bottom: 30px;
      }

      p {
        font-size: 24px;

        font-weight: 400;
        line-height: 35px;
        color: #999999;
      }
    }
  }

  .regulation {
    width: 690px;
    min-height: 613px;
    background: #ffffff;
    border-radius: 6px;
    padding: 48px 30px;
    padding-right: 22px;

    h3 {
      font-size: 28px;

      font-weight: 600;
      color: #000000;
      margin-bottom: 35px;
    }

    p {
      font-size: 24px;

      font-weight: 400;
      line-height: 35px;
      color: #999999;
    }
  }
}
</style>
